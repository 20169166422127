.table-sm td, .table-sm th {
  padding: .4rem;
}

.table-dark thead {
  background-color: #002;
}

.scroll-table {
  border-collapse: collapse !important;
  display: block; 
  overflow-x: auto;
  white-space: nowrap;
}

tr:nth-child(even)>td.highlight {
  background-color: #d9ffe3;
}

tr:nth-child(odd)>td.highlight {
  background-color: #cadecf;
}


.scroll-table tr>th:first-child, .scroll-table  tr>td:first-child {
  position: sticky;
  left: 0;
  background-color: #FFF !important;
}

.scroll-table tbody tr:nth-child(odd)>td:first-child  {
  background: #F4F4F4 !important;
}