.widget {width: 25%;}

@media (max-width: 1400px) {
  .widget {width: 50%;}
}

@media (max-width: 800px) {
  .widget {width: 100%;}
}

.widget-title {
  width: 100%;
  background-color: rgb(15, 23, 96);
  color: #FFF;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 4px;
}

.widget-title a {
  text-decoration: none;
  color: #FFF;
}
.widget-title a:hover {
  text-decoration: none;
  color: #999;
}

.widget-inner {
  border: 2px solid #999;
  border-radius: 8px;
  margin: 4px;
}

.widget-scroll {
  padding: 4px;
  height: 400px;
  overflow-y: scroll;
  padding-right: 12px;
}

.widget-item {
  width: 100%;
  margin-bottom: 4px;
  padding: 4px;
  border: 1px solid #999;
  cursor: pointer;
}

.widget-item.Catered-Chalet-Board,.widget-item.Bed---Breakfast {
  background-color: #8edbdc; 
}

.widget-item.Catered-Chalet-Board:hover,.widget-item.Bed---Breakfast:hover {
  background-color: #709aad; 
}

.widget-item.Self-Catered, .widget-item.Off-Contract {
  background-color: #979797; 
}
.widget-item.Self-Catered:hover, .widget-item.Off-Contract:hover {
  background-color: #666666; 
  color: white;
}

.status-0 {background-color: #F88;}
.status-0:hover {background-color: #D66;}
.status-1 {background-color: #FB0;}
.status-1:hover {background-color: #D80;}
.status-2 {background-color: #8F8;}
.status-2:hover {background-color: #6D6;}
.status-3 {background-color: #AAA;}
.status-3:hover {background-color: #999;}
.status-4 {background-color: #666;}
.status-4:hover {background-color: #999;}


.rating {
  border: 1px solid #999;
  min-width: 24px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.rating-1 {background-color: #F88;}
.rating-2 {background-color: #F88;}
.rating-3 {background-color: #FB0;}
.rating-4 {background-color: #8F8;}
.rating-5 {background-color: #8F8;}


.bold {font-weight: bold;}

.maintenance-summary {
  white-space: nowrap; 
  min-width: 30px; 
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
}