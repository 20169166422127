.grid-chalet {
  height: 50px;
  background-color: #FFF;
}

.grid-chalet:nth-of-type(odd) {
  background-color: #EEE;
}

.grid-staff {
  background-color: #FEE;
}

.grid-staff:nth-of-type(odd) {
  background-color: #EEF;
}

.grid-booking {
  background-color: #CF2C09; 
  height: 100%;
  top: 0%;
  position: absolute;
  border: 1px solid black; font-size: 12px;
  color: #FFF;
  text-decoration: none;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  line-height: 0.95rem;
}
.grid-booking:hover {
  background-color: #a32407; 
  color: white;
  text-decoration: none;
}

.grid-staff-houskeeping {
  height: 33%;
  top: 0%;
  background-color: #666;
}

.grid-staff-houskeeping1 {top: 33%;}
.grid-staff-houskeeping2 {top: 66%;}

.grid-staff-houskeeping:hover {
  background-color: #666;
}



.grid-booking.green {
  background-color: #8edc8f; 
  color: #000;
}


.grid-booking.green:hover {
  background-color: #355833; 
  color: white;
}

.grid-booking.housekeeping {
  background-color: #333; 
}

.grid-booking.housekeeping:hover {
  background-color: #999; 
}

.red.Self-Catered {
  background-color: #979797; 
}
.red.Self-Catered:hover {
  background-color: #666666; 
  color: white;
}

.grid-divider {
  position: absolute;
  width: 1px; 
  height: 100%;
  background-color: #999;
}

.grid-divider-day {
  position: absolute;
  width: 1px; 
  height: 100%;
  background-color: #DDD;
}

.grid-day {
  font-size: 8px;
  padding-bottom: 0px;
}

.grid-day.today {
  background-color: #8F8; 
  font-weight: bold;
}

.grid-today {
  width: 2px; 
  background-color: #000;
}

.grid-date {
  position: absolute;
  font-size: 14px;
}

.grid-day {
  position: absolute;
  font-size: 12px;
  width: 20px; 
  text-align: center;
}

.grid-day-housekeeping {
 background-color: #080;
}

.housekeeping-color {
  width: 34px; height: 32px;;
}

.housekeeping-color-1 {
  background-color: rgb(253, 98, 207);
}

.housekeeping-color-2 {
  background-color: rgb(31, 174, 15);
}

.housekeeping-color-3 {
  background-color: rgb(96, 76, 240);
}

.housekeeping-modal-day {
  width: 20px;
  height: 20px;
  margin-right: 1px;
  border: 1px solid #666;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

#bookingHouseKeeping::-webkit-scrollbar {
  display: none;
}
#bookingHouseKeeping {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.tooltip-500 {
  max-width: 800px;
}